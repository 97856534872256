<template>
  <BootstrapTooltip :content="content" :placement="placement">
    <slot></slot>
  </BootstrapTooltip>
</template>

<script>
export default {
  name: 'Tooltip',
  components: {
    BootstrapTooltip: () => import('@/components/bootstrap/BootstrapTooltip'),
  },
  props: {
    content: {
      type: String,
      required: true,
    },
    placement: {
      type: String,
      default: 'top',
    },
  },
};
</script>
